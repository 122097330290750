<template>
	<div class="huaxue_service">
		<div class="service_banner relative">
			<img class="img" src="../assets/static/service.png"/>
			<div class="title">
				<div class="fontsize48">项目服务</div>
				<div class="desc fontsize18">Project service</div>
			</div>
		</div>
		<div class="block30"></div>
		<!-- 项目公开 -->
		<div class="huaxue_type w1260">
			<div class="title fl fontsize38">项目公开</div>
			<div class="more fr fontsize22" @click="$util.routerPath('/infoItem',{type:'project'})">查看更多</div>
		</div>
		<div class="huaxue_project w1260">
			<div class="project_item" v-for="(item,index) in projectList" :key="index" @click="clickarticleDetail(item.id)">
				<div class="img">
					<img :src="item.imgUrl || '../assets/static/service.png'"/>
				</div>
				<div class="project_con">
					<div class="title fontsize16">{{item.title}}</div>
					<div class="desc fontsize14 clamp2">{{item.body}}</div>
				    <div class="more fontsize14">查看更多</div>
				</div>
			</div>
		</div>
		<div class="block30"></div>
		<!-- 专家团队 -->
		<div class="huaxue_type w1260">
			<div class="title fl fontsize38">专家团队</div>
			<div class="more fr fontsize22" @click="$util.routerPath('/infoItem',{type:'zjteam'})">查看更多</div>
		</div>
		<div class="huaxue_team relative w1240" v-if="swiperOptions.isshow">
			<div class="btn_prev" @click="slidePrev"><img src="../assets/static/icon_left.png"></div>
			<div class="btn_next" @click="slideNext"><img src="../assets/static/icon_right.png"></div>
			<div>
				<swiper ref="mySwiper" :options="swiperOptions">
				    <swiper-slide v-for="(item,index) in teamList" :key="index">
				    	<div class="team_item">
				    		<div class="team_con">
				    			<div class="img">
				    				<img :src="item.imgUrl || require('../assets/static/logo.png')"/>
				    			</div>
				    			<div class="title fontsize20">{{item.title}}</div>
				    			<div class="desc fontsize18">{{item.description || ""}}</div>
				    		</div>
				    	</div>
				    </swiper-slide>
				</swiper>
			</div>
		</div>
		<div class="block30"></div>
		<!-- 专家团队 -->
		<div class="huaxue_type w1260">
			<div class="title fl fontsize38">创新服务</div>
			<div class="more fr fontsize22" @click="$util.routerPath('/infoItem',{type:'cxservice'})">查看更多</div>
		</div>
		<div class="huaxue_innovate w1260">
			<div class="innovate_item relative" v-for="(item,index) in innovateList" :key="index" @click="clickarticleDetail(item.id)">
				<div class="img relative fl">
					<img :src="item.imgUrl || '../assets/static/logo.png'"/>
				</div>
				<div class="innovate_con fr">
					<div class="title fontsize16 clamp">{{item.title}}</div>
					<div class="desc fontsize14 clamp3">{{item.body}}</div>
				    <div class="more fontsize14">查看详情</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import {mapState} from 'vuex'
//页面引入swiper
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
	components: {
	    swiper,
	    swiperSlide
	},
	data() {
		return {
			projectList:[],
			teamList:[],
			swiperOptions:{
				isshow:false,
				slidesPerView: 4,
			},
			innovateList:[],//创新服务
		};
	},
	mounted: function() {
		if(document.body.clientWidth<750){
			this.swiperOptions.slidesPerView = 1
			console.log(this.swiperOptions.slidesPerView)
		}
		//项目公开
		this.getXiangmugongkai()
		this.getZhuanjiatuandui()
		this.getChuangxinfuwu()
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//跳转详情
		clickarticleDetail(uid){
			this.$router.push({ path: '/infoDetail', query: {uid: uid }})
		},
		//上一页
		slidePrev() {
		    this.$refs.mySwiper.swiper.slidePrev();
		},
		//下一页
		slideNext() {
		    this.$refs.mySwiper.swiper.slideNext()
		},
		//获取项目公开
		getXiangmugongkai() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 3,
			};
			this.$http.post('frontEnd/projectServices/getXiangmugongkai', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						records.forEach((item,index)=>{
							if(item.contents){
								item["body"] = _this.$util.filtersText(item.contents,118)
							}
						})
						_this.projectList = records
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取专家团队
		getZhuanjiatuandui() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 10,
			};
			this.$http.post('frontEnd/projectServices/getZhuanjiatuandui', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						_this.teamList = records
					}
					//展示
					_this.swiperOptions.isshow = true
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取创新服务
		getChuangxinfuwu() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 4,
			};
			this.$http.post('frontEnd/projectServices/getChuangxinfuwu', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						records.forEach((item,index)=>{
							if(item.contents){
								item["body"] = _this.$util.filtersText(item.contents,198)
							}
						})
						_this.innovateList = records
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
